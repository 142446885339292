import { NavLink } from "react-router-dom";
import Navbar from "../../components/es/Navbar";
import json from "../../components/es/env.json";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const editable = json;

const HomeEs = () => {
  return (
    <div>
      <div>
        <Navbar /> {/* composant navbar */}
        {/* debut de la section hero */}
        <div className="w-full h-screen bg-cover bg-center bg-bgb">
          <div className="backdrop-blur-lg flex justify-center items-center h-screen">
            <div className="max-xl:w-[80%] w-[40%]">
              <h1
                className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl"
                data-aos="zoom-in"
              >
                Alojamiento Confiable de Calidad a Precios Reducidos
              </h1>
              <p
                className="text-center mt-6 text-lg leading-8 text-white"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                By-Hoster fue creado para ofrecerte tarifas asequibles y
                servicios de alojamiento de alta calidad para ayudarte a
                materializar tus proyectos más grandes.
              </p>
              <p
                className="text-center mt-8"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="600"
              >
                <NavLink
                  to="https://client.by-hoster.net/store"
                  className="rounded-md bg-[#002BB7] p-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                >
                  <i className="fa-solid fa-cart-shopping text-white"></i>{" "}
                  Tienda
                </NavLink>
              </p>
            </div>
          </div>
        </div>
        {/* debut de la section services */}
        <div className="text-white bg-bg-g w-full bg-cover bg-center">
          <div className="backdrop-blur-lg">
            <div>
              <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="mx-auto max-w-lg text-center">
                  <h2
                    className="text-3xl font-bold sm:text-4xl"
                    data-aos="zoom-in"
                  >
                    Nuestros Servicios de Hospedaje
                  </h2>
                  <p className="mt-4 text-gray-300" data-aos="zoom-in">
                    Una lista de los servicios que ofrecemos
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/plesk"
                  >
                    <i className="fa-solid fa-globe fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje Web
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos hospedaje web para tus sitios a precios muy
                      accesibles.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/vps"
                  >
                    <i className="fa-solid fa-server fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje VPS
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos VPS a tarifas muy accesibles con protección
                      Anti-DDoS de alta calidad.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/GameBox"
                  >
                    <i className="fa-solid fa-gamepad fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje GameBox
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Puedes crear y gestionar múltiples servidores de juegos y
                      más sin la complejidad de la gestión de VPS, gracias a
                      nuestro panel.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gtarp"
                  >
                    <img
                      src="/img/fivem.svg"
                      className="w-10"
                      alt="Ícono vectorial de FiveM"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje FiveM
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos servidores FiveM con un rendimiento elevado para
                      una calidad de juego óptima.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/minecraft"
                  >
                    <img
                      src="/img/minecraft.svg"
                      className="w-8"
                      alt="Ícono vectorial de Minecraft"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje Minecraft
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos servidores de Minecraft para que puedas crear un
                      servidor para amigos o incluso para jugar públicamente sin
                      preocuparte por el rendimiento.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gmod"
                  >
                    <img
                      src="/img/gmod.svg"
                      className="w-10"
                      alt="Ícono vectorial de Garry's mod"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje Garry's Mod
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos servidores de Garry's Mod para un juego fácil y
                      bien rendido.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/rust"
                  >
                    <img
                      src="/img/rust.svg"
                      className="w-10"
                      alt="Ícono vectorial de Rust"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje Rust
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos servidores Rust de alto rendimiento para una
                      experiencia de juego óptima con una calidad de juego
                      inigualable.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/nodejs"
                  >
                    <i className="fa-brands fa-node-js fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje NodeJS
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos paquetes NodeJS si eres desarrollador de bots de
                      Discord u otras pruebas, con calidad de baja latencia a
                      precios asequibles para comenzar.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/python"
                  >
                    <i className="fa-brands fa-python fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hospedaje Python
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Ofrecemos paquetes Python3 si eres desarrollador de bots
                      de Discord u otras pruebas, con calidad de baja latencia a
                      precios asequibles para comenzar.
                    </p>
                  </a>
                </div>
                <div className="mt-12 text-center">
                  <NavLink
                    to="https://client.by-hoster.net/register"
                    className="p-2 rounded-md bg-[#002BB7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    Registrarse{" "}
                    <i className="fa-solid fa-arrow-right text-white"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          {/* debut de la section stats */}

          <div className="backdrop-blur-lg py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl
                className="grid grid-cols-1 gap-x-2 gap-y-16 text-center lg:grid-cols-4"
              >
                {editable.stats.map((stat) => (
                  <div
                    key={stat.id}
                    className="mx-auto flex max-w-xs flex-col gap-y-4"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <dt className="text-base leading-7 text-gray-200">
                      {stat.nom}
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      <i className={stat.fontawsome}></i>
                    </dd>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      {stat.chiffre}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* debut de la section presentation */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg">
            <section
              id="features"
              className="relative block px-6 py-10 md:py-20 md:px-10"
            >
              <div className="relative mx-auto max-w-5xl text-center">
                <h2
                  className="text-3xl font-bold sm:text-4xl"
                  data-aos="zoom-in"
                >
                  ¿Por qué elegir By-Hoster?
                </h2>
                <p className="mt-4 text-gray-300" data-aos="zoom-in">
                  By-Hoster es el proveedor de alojamiento ideal en Francia para
                  individuos, entusiastas y empresas. ¡El rendimiento de
                  nuestros servidores te permite tener la máxima velocidad para
                  tus proyectos web!
                </p>
              </div>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-database fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Tecnología
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Nuestras ofertas están impulsadas por las últimas
                    tecnologías para garantizar estabilidad para tus servicios.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Respaldo
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Contamos con un sistema de respaldo automático y programado
                    todos los días para asegurar tus datos.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-phone-volume fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Soporte</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    El soporte al cliente es nuestra prioridad. Nos esforzamos
                    por proporcionar el mejor soporte posible a nuestros
                    clientes.
                  </p>
                </div>
              </div>
              <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full"></div>
              <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full"></div>
            </section>
          </div>

          {/* debut de la section faq */}

          <hr className="mx-auto w-[80%]" />
          <div className="backdrop-blur-lg py-8 sm:py-12">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Preguntas Frecuentes.
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Respuestas a las preguntas más comunes.
            </p>
            <div
              className="mt-12 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src="/img/faq.webp"
                className="xl:mr-28 max-sm:w-64 w-96 cloudeffect max-xl:mx-auto max-xl:py-8"
                alt="Imagen de la sección de preguntas frecuentes"
              />
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="200"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="xl:pr-[191px]">
                        ¿Cuentan con protección Anti-DDoS de calidad?
                      </span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      ¡Sí, por supuesto! Contamos con protección Anti-DDoS en
                      las capas 4 y 7.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="400"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>¿Dónde están ubicadas sus máquinas?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Toda nuestra infraestructura está ubicada en Francia,
                      nuestras máquinas e infraestructura pertenecen a By-Hoster
                      y están situadas precisamente en la región de "Nueva
                      Aquitania".
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="600"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>¿Ofrecen soporte 24/7?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      ¡Nuestro soporte está activo todos los días y se esfuerza
                      por responder lo más rápido posible!
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>

          {/* debut de la section avis */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8">
            <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
              <h1
                className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
                data-aos="zoom-in"
              >
                Reseñas de Clientes
              </h1>
              <p
                className="mt-4 text-gray-300 mb-8 text-center"
                style={{ fontStyle: "italic" }}
                data-aos="zoom-in"
              >
                Algunas reseñas han sido parafraseadas para mayor claridad, pero
                la esencia y la opinión general de los clientes se han
                preservado. Las modificaciones no han alterado el significado o
                la integridad de los comentarios originales. Las reseñas están
                traducidas del francés
              </p>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Ícono de usuario de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Tom Marcos
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "Compré un VPS de ellos, no podía configurar un panel
                    Pterodactyl. Abrí un ticket y, en 10 minutos, lo tenía. ¡Por
                    eso recomiendo mucho By-Hoster!"
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Ícono de usuario de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Le1er Xero
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "Un proveedor de alojamiento que es respetuoso con sus
                    clientes, con un soporte muy receptivo y un excelente
                    servicio de alojamiento para ofrecer. Creo que puedo decir
                    que es un proveedor de alojamiento de calidad."
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Ícono de usuario de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Nain Ptit
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "Llevo usando este proveedor de alojamiento no por mucho
                    tiempo para un sitio de servidor de Minecraft, y lo
                    encuentro súper conveniente. Prácticamente no hay retraso en
                    la versión más económica."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* debut de la section footer */}
        <footer className="bg-footerbg w-full bg-cover bg-center">
          <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
                <img
                  src="/img/logo-big.webp"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  className="w-44"
                  alt="Logo de By-Hoster"
                />
                <p
                  className="mt-4 max-w-xs text-white"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  By-Hoster fue creado en 2020 por Planetium Network para
                  ofrecerte tarifas asequibles y servicios de alojamiento de
                  calidad que te ayuden a llevar a cabo tus proyectos más
                  importantes.
                </p>
                <ul className="mt-8" data-aos="fade-up" data-aos-easing="ease">
                  <li>
                    <a
                      href="mailto:contact@by-hoster.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p className="">
                        <i className="fa-solid fa-envelope"></i>{" "}
                        contact@by-hoster.com
                      </p>
                    </a>
                  </li>
                  <li>
                    <div className="text-gray-300 transition hover:opacity-75">
                      <p>
                        <i className="fa-solid fa-globe"></i> www.by-hoster.net
                      </p>
                    </div>
                  </li>
                  <li>
                    <a
                      href="tel:+33564720053"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p>
                        <i className="fa-solid fa-phone"></i> +33564720053
                      </p>
                    </a>
                  </li>
                  <li className="mt-8">
                    <a
                      href="https://www.top-heberg.com/hebergeur/by-hoster-300"
                      target="_blank"
                      title="By Hoster en Top-heberg.com"
                    >
                      <img
                        src="https://www.top-heberg.com/badge/by-hoster-300?s=refer"
                        width="250"
                        alt="Top-heberg.com"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div>
                  <p className="font-medium text-white">Servicios de Juegos</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/GameBox"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento GameBox
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gtarp"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento GTA RP
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/minecraft"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento Minecraft
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gmod"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento Garry's Mod
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/rust"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento Rust
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Servicios en la Nube</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/vps"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento VPS
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/plesk"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento WEB
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/nodejs"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento NodeJS
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/python"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Alojamiento Python
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Otros Enlaces</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/client"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Área de Cliente
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://by-h.cloud"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        ByCloud
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://statut.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Estado
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:contact@by-hoster.com"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Contáctenos
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Legal (en francés)</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="/CGU.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Términos y Condiciones
                      </a>
                    </li>
                    <li>
                      <a
                        href="/ML.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Avisos Legales
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomeEs;
