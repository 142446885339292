import { NavLink } from "react-router-dom";
import Navbar from "../../components/fr/Navbar";
import json from "../../components/fr/env.json";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const editable = json;

const Home = () => {
  return (
    <div>
      <div>
        <Navbar /> {/* composant navbar */}
        {/* debut de la section hero */}
        <div className="w-full h-screen bg-cover bg-center bg-bgb">
          <div className="backdrop-blur-lg flex justify-center items-center h-screen">
            <div className="max-xl:w-[80%] w-[40%]">
              <h1
                className="text-center text-4xl font-bold tracking-tight text-white sm:text-6xl"
                data-aos="zoom-in"
              >
                Qualité d'hébergement fiable à tarifs avantageux
              </h1>
              <p
                className="text-center mt-6 text-lg leading-8 text-white"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="400"
              >
                By-Hoster a été créé pour vous offrir des tarifs abordables et
                des services d'hébergement qualitatif afin de vous aider à
                réaliser vos plus grands projets.
              </p>
              <p
                className="text-center mt-8"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="600"
              >
                <NavLink
                  to="https://client.by-hoster.net/store"
                  className="rounded-md bg-[#002BB7] p-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                >
                  <i class="fa-solid fa-cart-shopping text-white"></i> Boutique
                </NavLink>
              </p>
            </div>
          </div>
        </div>
        {/* debut de la section services */}
        <div className="text-white bg-bg-g w-full bg-cover bg-center">
          <div className="backdrop-blur-lg">
            <div>
              <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
                <div className="mx-auto max-w-lg text-center">
                  <h2
                    className="text-3xl font-bold sm:text-4xl"
                    data-aos="zoom-in"
                  >
                    Nos Services d'hébergement
                  </h2>
                  <p className="mt-4 text-gray-300" data-aos="zoom-in">
                    Une liste des services que nous proposons
                  </p>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/plesk"
                  >
                    <i className="fa-solid fa-globe fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement WEB
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons l'hébergement de vos sites WEB a prix très
                      réduit.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/vps"
                  >
                    <i className="fa-solid fa-server fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement VPS
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des VPS qualitatifs avec une
                      protection Anti-DDoS !
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/GameBox"
                  >
                    <i className="fa-solid fa-gamepad fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement GameBox
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Vous pouvez créer et gérer plusieurs serveurs de jeux et
                      autres sans la complexité de la gestion d'un VPS grâce à
                      notre panel complet et clé en main.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gtarp"
                  >
                    <img
                      src="/img/fivem.svg"
                      className="w-10"
                      alt="icône vectorielle de FiveM"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement FiveM
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des serveurs FiveM avec des hautes
                      performances pour jouer avec une qualité de gameplay
                      optimale !
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/minecraft"
                  >
                    <img
                      src="/img/minecraft.svg"
                      className="w-8"
                      alt="icône vectorielle de Minecraft"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement Minecraft
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des serveurs Minecraft pour que vous
                      puissiez créer un serveur entre amis ou même publique pour
                      jouer et s'amuser sans se soucier des performances !
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/gmod"
                  >
                    <img
                      src="/img/gmod.svg"
                      className="w-10"
                      alt="icône vectorielle de Garry's mod"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement Garry's Mod
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des serveurs Garry's Mod pour que vous
                      puissiez jouer facilement sur un serveur qui tourne bien.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/rust"
                  >
                    <img
                      src="/img/rust.svg"
                      className="w-10"
                      alt="icône vectorielle de Rust"
                    />
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement Rust
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des serveurs Rust à hautes performances
                      pour une expérience de jeu optimale avec une qualité de
                      gameplay inégalée.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/nodejs"
                  >
                    <i className="fa-brands fa-node-js fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement NodeJS
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des offres NodeJS si vous êtes développeur
                      de bot discord ou tout autre utilisation, avec une qualité sans
                      latence à bas prix pour débuter.
                    </p>
                  </a>
                  <a
                    className="block rounded-xl border  p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                    href="https://client.by-hoster.net/store/python"
                  >
                    <i className="fa-brands fa-python fa-2xl"></i>
                    <h2 className="mt-4 text-xl font-bold text-white">
                      Hébergement Python
                    </h2>
                    <p className="mt-1 text-sm text-gray-300">
                      Nous proposons des offres Python3 si vous êtes développeur
                      de bot discord ou tout autre utilisation, avec une qualité sans
                      latence à bas prix pour débuter.
                    </p>
                  </a>
                </div>
                <div className="mt-12 text-center">
                  <NavLink
                    to="https://client.by-hoster.net/register"
                    className="p-2 rounded-md bg-[#002BB7] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#000C36] focus-visible:outline focus-visible:outline-2"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    Inscription <i class="fa-solid fa-arrow-right text-white"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <hr className="mx-auto w-[80%]" />

          {/* debut de la section stats */}

          <div className="backdrop-blur-lg py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-2 gap-y-16 text-center lg:grid-cols-4">
                {editable.stats.map((stat) => (
                  <div
                    key={stat.id}
                    className="mx-auto flex max-w-xs flex-col gap-y-4"
                    data-aos="fade-up"
                    data-aos-easing="ease"
                  >
                    <dt className="text-base leading-7 text-gray-200">
                      {stat.nom}
                    </dt>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      <i className={stat.fontawsome}></i>
                    </dd>
                    <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                      {stat.chiffre}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* debut de la section presentation */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg">
            <section
              id="features"
              className="relative block px-6 py-10 md:py-20 md:px-10"
            >
              <div className="relative mx-auto max-w-5xl text-center">
                <h2
                  className="text-3xl font-bold sm:text-4xl"
                  data-aos="zoom-in"
                >
                  Pourquoi Choisir By-Hoster ?
                </h2>
                <p className="mt-4 text-gray-300" data-aos="zoom-in">
                  By-Hoster est l'hébergeur français idéal pour les
                  particuliers, passionnés et entreprises. La performance de nos
                  serveurs vous permet de disposer d'une vitesse maximale pour
                  vos projets digital !
                </p>
              </div>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-database fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Technologie
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Nos offres sont propulsées par les dernières technologies
                    afin de garantir une stabilité sur vos services.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-cloud-arrow-up fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Sauvegarde
                  </h2>
                  <p className="mt-1 text-sm text-gray-300">
                    Nous disposons d'un système de sauvegarde automatisé, 
                    avec des sauvegardes quotidiennes planifiées, 
                    afin de garantir la sécurité et l'intégrité de vos données.
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <i className="fa-solid fa-phone-volume fa-2xl"></i>
                  <h2 className="mt-4 text-xl font-bold text-white">Support</h2>
                  <p className="mt-1 text-sm text-gray-300">
                    L'accompagnement de nos clients est notre priorité, Nous
                    mettons un point d'honneur à vous proposer le meilleur
                    support possible.
                  </p>
                </div>
              </div>
              <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full"></div>
              <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full"></div>
            </section>
          </div>

          {/* début de la section faq */}

          <hr className="mx-auto w-[80%]" />
          
          <div className="backdrop-blur-lg py-8 sm:py-12">
            <h1
              className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
              data-aos="zoom-in"
            >
              Questions fréquemment posées.
            </h1>
            <p
              className="mt-4 text-gray-300 mb-4 text-center"
              data-aos="zoom-in"
            >
              Réponses aux questions les plus courantes.
            </p>
            <div
              className="mt-12 xl:flex justify-center xl:items-center"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              <img
                src="/img/faq.webp"
                className="xl:mr-28 max-sm:w-64 w-96 cloudeffect max-xl:mx-auto max-xl:py-8"
                alt="Image de la section FAQ"
              />
              <div className="grid divide-y divide-neutral-200 max-w-xl max-sm:w-64 max-xl:mx-auto">
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="200"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="xl:pr-[277px]">
                        Avez vous un Anti-DDoS de qualité ?
                      </span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Oui, bien-sûr ! Nous possédons un Anti-DDoS de type Layer
                      4 et Layer 7.
                    </p>
                  </details>
                </div>
                <div
                  className="py-5"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  data-aos-delay="400"
                >
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Où vos machines sont-elles hébergées ?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Toute notre infrastructure est localisée en France, nos
                      machines et l'infrastucture appartiennent à By-Hostrer et
                      sont précisément située en région "Nouvelle-Aquitaine".
                    </p>
                  </details>
                </div>
                <div className="py-5" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span className="xl:pr-[277px]">Quelles options de paiement acceptez vous ?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      By-Hoster propose une variété d'options de paiement pour faciliter votre expérience.
                      Nous acceptons les paiements via des plateformes populaires telles que PayPal, Stripe (carte bancaire) 
                      et éventuellement PaySafeCard. De plus, nous sommes ouverts à d'autres méthodes de paiement sur demande, 
                      telles que les virements bancaires (IBAN), afin de mieux répondre à vos besoins spécifiques. 
                      Notre objectif est de rendre le processus de paiement aussi simple et pratique que possible pour vous.
                    </p>
                  </details>
                </div>
                <div className="py-5" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Proposez vous du Support 24h/24 et 7j/7 ?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Notre support est actif tous les jours et fait de son
                      mieux pour vous répondre dans les plus brefs délais !
                    </p>
                  </details>
                </div>
                <div className="py-5" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                  <details className="group">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                      <span>Pouvons-nous essayer vos services ?</span>
                      <span className="transition group-open:rotate-180">
                        <svg
                          fill="none"
                          height={24}
                          shapeRendering="geometricPrecision"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          width={24}
                        >
                          <path d="M6 9l6 6 6-6" />
                        </svg>
                      </span>
                    </summary>
                    <p className="text-gray-400 mt-3 group-open:animate-fadeIn">
                      Nous offrons à tous la possibilité d'essayer gratuitement pendant 24 heures le service de leur choix, afin de leur permettre de constater concrètement la qualité que nous proposons.
                    </p>
                  </details>
                </div>
              </div>
            </div>
          </div>

          {/* debut de la section avis */}

          <hr className="mx-auto w-[80%]" />

          <div className="backdrop-blur-lg py-8">
            <div className="backdrop-blur-lg mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
              <h1
                className="mb-4 text-center text-3xl font-bold sm:text-4xl tracking-tight text-white"
                data-aos="zoom-in"
              >
                Les avis de nos clients
              </h1>
              <p
                className="mt-4 text-gray-300 mb-8 text-center"
                style={{ fontStyle: "italic" }}
                data-aos="zoom-in"
              >
                Certains avis ont été reformulés pour des raisons de clarté,
                mais l'essence et l'opinion générale des clients ont été
                préservées. Les modifications n'ont pas altéré le sens ou
                l'intégrité des commentaires d'origine.
              </p>
              <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Icône utilisateur de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Tom Marcos
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "J'ai acheté un VPS chez eux, je n'arrivais pas a mettre un
                    panel Pterodactyl, j'ouvre un ticket en 10 minutes, je
                    l'avais c'est pour cela que je recommande fortement
                    By-Hoster !"
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                    <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Icône utilisateur de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Le1er Xero
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "Un hébergeur qui est respectueux envers ses clients, avec
                    un support très réactif et un très bon service d'hébergement
                    à proposer. Je pense que je peux dire que c'est un hébergeur
                    de qualité !"
                  </p>
                </div>
                <div
                  className="block rounded-xl border text-center p-8 shadow-xl transition hover:border-[#4C57D6] hover:shadow-[#4C57D6]"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  <div className="flex justify-center">
                  <img
                      src="/img/user.webp"
                      className="w-12 rounded-md"
                      alt="Icône utilisateur de top-heberg.com"
                    />
                  </div>
                  <h2 className="mt-4 text-xl font-bold text-white">
                    Nain Ptit
                  </h2>
                  <div className="flex justify-center mb-4">
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                    <svg
                      className="w-4 h-4 text-yellow-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  </div>
                  <p className="mt-1 text-sm text-gray-300">
                    "J'utilise cet hébergeur depuis pas très longtemps pour un
                    site de serveur Minecraft, et je le trouve super pratique.
                    Il n'y a pratiquement pas de lag pour la version la moins
                    chère."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* debut de la section footer */}
        <footer className="bg-footerbg w-full bg-cover bg-center">
          <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
                <img
                  src="/img/logo-big.webp"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                  className="w-44"
                  alt="Logo de By-Hoster"
                />
                <p
                  className="mt-4 max-w-xs text-white"
                  data-aos="fade-up"
                  data-aos-easing="ease"
                >
                  By-Hoster a été créé en 2020 par Planetium Network pour vous
                  offrir des tarifs abordables et des services d'hébergement
                  qualitatif afin de vous aider à réaliser vos plus grands
                  projets.
                </p>
                <ul className="mt-8" data-aos="fade-up" data-aos-easing="ease">
                  <li>
                    <a
                      href="mailto:contact@by-hoster.com"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p className="">
                        <i className="fa-solid fa-envelope"></i>{" "}
                        contact@by-hoster.com
                      </p>
                    </a>
                  </li>
                  <li>
                    <div className="text-gray-300 transition hover:opacity-75">
                      <p>
                        <i className="fa-solid fa-globe"></i> www.by-hoster.net
                      </p>
                    </div>
                  </li>
                  <li>
                    <a
                      href="tel:+33564720053"
                      rel="noreferrer"
                      target="_blank"
                      className="text-gray-300 transition hover:opacity-75"
                    >
                      <p>
                        <i className="fa-solid fa-phone"></i> 05 64 72 00 53
                      </p>
                    </a>
                  </li>
                  <li className="mt-8">
                    <a
                      href="https://www.top-heberg.com/hebergeur/by-hoster-300"
                      target="_blank"
                      title="By Hoster sur Top-heberg.com"
                    >
                      <img
                        src="https://www.top-heberg.com/badge/by-hoster-300?s=refer"
                        width="250"
                        alt="Top-heberg.com"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4"
                data-aos="fade-up"
                data-aos-easing="ease"
              >
                <div>
                  <p className="font-medium text-white">Services Gaming</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/GameBox"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement GameBox
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gtarp"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement GTA RP
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/minecraft"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement Minecraft
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/gmod"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement Garry's Mod
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/rust"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement Rust
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Services Cloud</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/vps"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement VPS
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/plesk"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement WEB
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/nodejs"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement NodeJS
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/store/python"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Hébergement Python
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Autres liens</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="https://client.by-hoster.net/client"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Espace Client
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://by-h.cloud"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        ByCloud
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://statut.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Statut
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://forum.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Forum
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.by-hoster.net"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Documentation
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://client.by-hoster.net/client/support/create"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Ouvrir une demande
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-medium text-white">Légal</p>
                  <ul className="mt-6 space-y-4 text-sm">
                    <li>
                      <a
                        href="/CGU.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="/ML.pdf"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Mentions Légales
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://report.by-hoster.com"
                        className="text-gray-300 transition hover:opacity-75"
                      >
                        Formulaire de signalement de contenu
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
